import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
// import Pagination from "../components/common/Pagination";
import { constructActiveDoc } from "../utilities";

const PostList = ({ data, pageContext, location }) => {
  if (!data) return null;
  const { settings, page, posts } = data;;
  const postIdeas = location.search.split("ideas=")[1];

  const postListFiltered = posts.nodes.filter((post) => {
    if (postIdeas === undefined) return true;
    return post.tags.includes(postIdeas);
  });

  const postList = postListFiltered.map((post) => {
    return (
      <Col
        as={Anchor}
        href={`${post.url}`}
        title={post.data.title}
        key={post.url}
        md={6}
        className="mb-4 mb-md-5 d-flex flex-column justify-content-between"
      >
        <div className="d-flex align-items-center justify-content-center h-100">
          <GatsbyImage
            image={post.data.post_main_image[0].main_image.gatsbyImageData}
            alt={
              post.data.post_main_image[0].main_image.alt || "Post main image"
            }
            className="rounded"
          />
        </div>
        <h3 className="my-3">{post.data.title}</h3>
      </Col>
    );
  });

  return (
    <>
      <Seo page={page} settings={settings} itemsList={posts} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <Container className="my-5">
          {postListFiltered.length ? (
            <Row>{postList}</Row>
          ) : (
            <h3 className="text-center">
              Δεν υπάρχουν ακόμα άρθρα για αυτή την κατηγορία.
            </h3>
          )}
          {/* <Pagination
            pageInfo={posts.pageInfo}
            base={pageContext.base}
            enableArrows
          /> */}
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query postListQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicPostList(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        type
        uid
        lang
      }
      lang
      type
      url
      data {
        title
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
        }
      }
    }
    posts: allPrismicPost(
      sort: { fields: last_publication_date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        url
        tags
        data {
          title
          post_main_image {
            main_image {
              alt
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(PostList);

// pageInfo {
//   currentPage
//   hasNextPage
//   hasPreviousPage
//   pageCount
// }
